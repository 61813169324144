

import defaultFieldProps from '~/components/FlexibleSections/Blocks/defaultFieldProps';

export default {
    mixins: [
        defaultFieldProps
    ],
    props: {
        background: {
            type: String,
            default: 'default'
        },
        video: {
            type: String,
            required: true,
            default: null
        },
        caption: {
            type: String,
            required: false,
            default: ''
        },
        richText: {
            type: String,
            required: true,
            default: ''
        },
        plaintext: {
            type: String,
            required: true,
            default: ''
        },
        videoAlignment: {
            type: String,
            required: false,
            default: 'left'
        },
        linkBlock: {
            type: Array,
            default() { return null; }
        }
    },

    computed: {
        hasVideoOrText() {
            return this.video || this.richText;
        }
    }
};
