
export default {
    props: {
        text: {
            type: String,
            required: true,
            default: ''
        }
    }
};
