
export const LEFT = 'left';
export const RIGHT = 'right';

export default {
    props: {
        video: {
            type: String,
            required: false,
            default: ''
        },
        caption: {
            type: String,
            required: false,
            default: ''
        },
        richText: {
            type: String,
            required: true,
            default: ''
        },
        heading: {
            type: String,
            required: false,
            default: ''
        },
        videoAlignment: {
            type: String,
            required: false,
            default: LEFT,
            validator: value => [LEFT, RIGHT].includes(value),
        },
        linkBlock: {
            type: Array,
            default: () => { return null; }
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    }
};
