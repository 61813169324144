
const SERVICE_YOUTUBE = 'youtube',
    SERVICE_VIMEO = 'vimeo',
    YTREGEX = /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:youtube\.com|youtu.be)(?:\/(?:(?:.+)[?&]v=|embed\/|v\/)?)([\w-]+)(?:\S+)?$/i,
    VIMEOREGEX = /^https:\/\/vimeo\.com\/([0-9]+)$/i;

export default {
    props: {
        url: {
            type: String,
            required: true,
            default: ''
        },

        caption: {
            type: String,
            required: false,
            default: ''
        },

        loading: {
            type: String,
            default: 'lazy'
        }
    },
    data() {
        return {
            SERVICE_VIMEO,
            SERVICE_YOUTUBE
        };
    },
    computed: {
        service() {
            return this.parsed.service;
        },
        id() {
            return this.parsed.id;
        },
        parsed() {
            return this.parseUrl(this.url);
        }
    },

    methods: {
        parseUrl(url) {
            const service = this.parseService(url);

            return {
                service,
                id: this.parseId(service, this.url)
            };
        },
        parseService(url) {
            if (url.match(YTREGEX)) {
                return SERVICE_YOUTUBE;
            }

            if (url.match(VIMEOREGEX)) {
                return SERVICE_VIMEO;
            }

            return null;
        },
        parseId(service, url) {
            switch (service) {
            case SERVICE_YOUTUBE: {
                const matches = url.match(YTREGEX);
                return matches[1];
            }
            case SERVICE_VIMEO: {
                const matches = url.match(VIMEOREGEX);
                return matches[1];
            }
            }

            return null;
        }
    }
};

