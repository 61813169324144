import { render, staticRenderFns } from "./TextVideo.vue?vue&type=template&id=7c5611ba"
import script from "./TextVideo.vue?vue&type=script&lang=js"
export * from "./TextVideo.vue?vue&type=script&lang=js"
import style0 from "./TextVideo.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoEmbed: require('/vercel/path0/components/VideoEmbed/VideoEmbed.vue').default,BaseIcon: require('/vercel/path0/components/BaseIcon/BaseIcon.vue').default,LinkBlock: require('/vercel/path0/components/LinkBlock/LinkBlock.vue').default,RichText: require('/vercel/path0/components/RichText/RichText.vue').default})
